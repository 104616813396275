<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex">
						<a-col span="2" v-if="addmail || !isShow">
							<common-page-btn @send="sendmail">
								<template #mySlot>
									<a-button v-if="!isShow" @click="black">返回</a-button>
								</template>
							</common-page-btn>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>
		<div v-if="addmail">
			<div v-if="isShow">
				<a-table :columns="columns" :pagination="false" row-key="id" :data-source="data.records" :scroll="{ x: '100%' }"
								 :indentSize="25" :defaultExpandAllRows="true" size="small" :style="{ background: '#FFF' }">
					<!-- 主题 -->
					<template slot-scope="row" slot="tit">
						<a @click="enter(row)">{{ row.title }}</a>
					</template>
					<!-- 操作 -->
					<template slot-scope="row" slot="action">
						<a-tooltip title="删除">
							<a-button type="link" v-has="'mail:send:del'" icon="delete" @click="del(row.id)"></a-button>
						</a-tooltip>
					</template>
				</a-table>
				<a-row>
					<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
						<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
													:current="data.current" />
					</a-col>
				</a-row>
			</div>
			<!-- 查看信件 -->
			<mail :mail="rowMail" :type="'发件箱'" v-else></mail>
		</div>
		<postmail v-if="!addmail" @success="onsubmit" :isempty="true" />
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import { Icon } from 'ant-design-vue';
import attachView from '@/components/common/attach-view.vue';
import postmail from './components/postmail.vue';
import mail from './components/mail.vue';
const MyIcon = Icon.createFromIconfontCN({
	scriptUrl: '//at.alicdn.com/t/c/font_3394332_2wzi7hji7im.js' // 在 iconfont.cn 上生成
});
export default {
	components: { 'my-icon': MyIcon, postmail, attachView, mail },
	data() {
		return {
			addmail: true,
			isShow: true,
			mailId: '',

			columns: [
				{ title: '是否发送', dataIndex: 'status', align: 'center', width: 100 },
				{ title: '主题', scopedSlots: { customRender: 'tit' }, width: 200 },
				{ title: '收件人', dataIndex: 'receiveNames', align: 'left', width: 200, ellipsis: true },
				{ title: '时间', dataIndex: 'createTime', align: 'center', width: 160 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			],
			rowMail: {},
			data: {},
			param: {
				page: 1,
				limit: 20
			}
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		...apiUtil,
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/mail/send/issue-list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 点击返回
		black() {
			this.isShow = true;
			this.getList();
		},
		sendmail() {
			this.addmail = false;
		},
		// 查看邮件
		enter(row) {
			this.mailId = row.id;
			this.rowMail = row;
			this.isShow = false;
		},

		// 删除发送的邮件
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/mail/send/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.isShow = true;
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
				});
			});
		},
		onsubmit() {
			this.addmail = true;
			this.getList();
		}
	}
};
</script>

<style scoped lang="less">
.title {
	font-size: 30px;
}

.mailtitle {
	font-size: 28px;
}

.container {
	display: flex;
	text-align: center;
}
</style>
